'use strict';
//import 'bootstrap/dist/js/bootstrap.bundle';
//import bsCollapse from 'bootstrap/js/dist/collapse';

//import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import Collapse from 'bootstrap/js/dist/collapse';
import Modal from 'bootstrap/js/dist/modal';

//import { Collapse } from 'bootstrap';

/*import "aos";
var AOS = require('aos');

AOS.init();*/

import Splide from '@splidejs/splide';




// Look for .hamburger
var hamburger = document.querySelector(".hamburger");
// On click
hamburger.addEventListener("click", function() {
    // Toggle class "is-active"
    hamburger.classList.toggle("is-active");
    // Do something else, like open/close menu
});

function appHeight(){
  const doc = document.documentElement;
  const h = window.innerHeight;
  const hpx = h + 'px';
  doc.style.setProperty('--app-height', hpx );
}

window.addEventListener('resize', appHeight);
appHeight();



if(page === "main"){
  console.log("ja ist main");

  var secOthers = document.querySelector("#sectionOthers");
  
  if(secOthers){  
    var splide = new Splide( '.splide', {
      perPage: 2,
      rewind : false,
      type   : 'loop',
      pagination: false,
      /*pagination: false,*/
      gap    : '2rem',
      breakpoints: {
        768: {
          perPage: 1,
          gap    : '.7rem',
        }
      },
    } );
    
    splide.mount();  
  }

  var submit   = document.getElementById("senden");
  var checker  = document.getElementsByName("dsgvo");
  var formular = document.getElementById("form");
  var checkbox = checker[0];
  if ( checkbox.checked ) { submit.disabled = false; } else { submit.disabled = true; }
  checkbox.onclick = function () { if ( checkbox.checked ) { submit.disabled = false; } else { submit.disabled = true; } };

  submit.addEventListener("click", sendeFormular);

}



function validateEmail(email){
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function validate() {
  
  const inputs = document.querySelectorAll('.input');

  inputs.forEach(i => {    
    i.classList.remove('not-valide');
  });
  
  
  var error = 1;
  
  if( formular.name.value == "" ) {
    formular.name.classList.add("not-valide");
    formular.name.focus();
    error = 2;
  }

  if( formular.email.value == "" ) {
    formular.email.classList.add("not-valide");
    formular.email.focus() ;
    error = 2;
  }

  if( formular.text.value == "" ) {
    formular.text.classList.add("not-valide");
    formular.text.focus() ;
    error = 2;
  }
  
  if(validateEmail( formular.email.value )){
    
    /* alles ok */
    
  }else{
    formular.email.classList.add("not-valide");
    formular.email.focus();
    error = 2;
  }
  

  if(error < 2){    
    return( true );
  }else{
    return false;
  }    
  
}


function sendeFormular() {
  var error = 1;
  
  if(validate()){
  
  const XHR = new XMLHttpRequest();  
  XHR.open("POST", "ajax-form.php" + "?data", true);
  
  let daten = new FormData(formular);
  
  XHR.send(daten);
  
  XHR.onreadystatechange = function () {
    if (XHR.readyState == 4 && XHR.status == 200) {
     
     var data = JSON.parse(XHR.responseText);
     
     console.log(data.success);
     
     if(data.success === true){
       console.log("juhu");
     }else{
        console.log(data.error);
        data.error.forEach(function(item) {
          var el = formular.querySelector('input[name="' + item +'"]');
          el.classList.add("not-valide");
          error = 2;
        });
       
     }
     
      if(error < 2 && data.error_mail == ''){    
        document.getElementById("ausgabe").innerHTML = data.meldung;
        formular.reset();
      }else{
        document.getElementById("ausgabe").innerHTML = data.meldung;
      } 
     
    }
  }
  
  }else{
    console.log('nicht validiert');
  }
}


window.addEventListener('DOMContentLoaded', (event) => {
  const navLinks = document.querySelectorAll('.nav-item');
  const menuToggle = document.getElementById('navbarSupportedContent');
  const bsCollapse = new Collapse(menuToggle, {
    toggle: false
  });
  navLinks.forEach((l) => {
      l.addEventListener('click', () => { 
        bsCollapse.toggle(); 
        hamburger.classList.toggle("is-active");
      })
  });
});


/* parallax Helper for z-Index iOS */
var fgParallax = true;

function fg_parallax(){
  if(fgParallax){

    const elements  = document.querySelectorAll('.fg-parallax');
    
    let zIndex = 1;

    elements.forEach((item)=>{

      item.style.zIndex    = zIndex;
      zIndex++;

    });
  }
}

window.addEventListener('load', fg_parallax() );
    

addEventListener('click', function (ev) {
    if (ev.target.classList.contains('no-hash')) {      
      ev.preventDefault();
     document.querySelector('[name="' + ev.target.dataset.link + '"]').scrollIntoView({
        behavior: 'smooth'
      });
    }   
});



function sprache() {
  console.log("sprache loaded");
  const sprache = document.querySelectorAll('.toogleLanguage');

  const spracheAktivEl = document.querySelectorAll('.lanaguage-selected');
  
  let contentSprache = document.querySelectorAll('.contentSprache');

  sprache.forEach((item)=> {
    console.log("sprache Button loaded");
    item.addEventListener('click', event => {
      var el = event.target;
      var i = 0;

      for (i = 0; i < contentSprache.length; ++i) {
        contentSprache[i].classList.toggle('d-none');
      }

    });
  });
}

document.addEventListener('DOMContentLoaded', sprache());
